import { Component } from "@angular/core";
import { UserService } from "../services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../model/user";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent {
  username: string;
  name: string;
  surnames: string;
  password: string;
  role: string;
  dni: string;
  email: string;
  tlf: string;
  subscription: string;
  startDate: Date;
  endDate: Date;
  errorMessage: string;

  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (window.localStorage.getItem("username") != null) {
      this.router.navigate(['mainMenu']);
    }
  }

  register() {
    if (!this.email || !this.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      this.errorMessage = "Por favor, introduce un correo electrónico válido.";
      return;
    }

    if (!this.dni || !this.dni.match(/^\d{8}[A-Za-z]$/)) {
      this.errorMessage = "Por favor, introduce un DNI válido.";
      return;
    }
    if (!this.tlf || !this.tlf.match(/^\d{9}$/)) {
      this.errorMessage = "Por favor, introduce un número de teléfono válido (9 dígitos).";
      return;
    }
    this.userService.getUserInfo(this.username).subscribe(
      () => {
        this.errorMessage = "El usuario ya existe"
      },
      error => {
        const currentDate = new Date();
        this.startDate = currentDate;
        this.subscription = "Normal"
        this.role = "user"
        this.userService.register(this.username, this.password, this.name, this.surnames, this.dni, this.tlf, this.email, this.startDate, this.role, this.subscription).subscribe(
          user => {
            this.errorMessage = "USUARIO REGISTRADO CON ÉXITO"
            this.router.navigate(['/login']);
          },
          error => {
            this.errorMessage = 'Error durante el registro. Por favor, verifica tus datos e inténtalo nuevamente.';
          }
        );
      }
    );
  }
}
