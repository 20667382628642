import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { Observable, throwError } from 'rxjs';
import { map, tap } from "rxjs/operators"
import { catchError } from "rxjs/operators"

@Injectable({ providedIn: 'root' })

export class SuscriptionService {
    private urlEndpoint: string = 'https://public-area-7744833acd72.herokuapp.com/v1/payment';
    private header = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient, private router: Router) {

    }
    createPayment(amount : number, days: number, username: string): Observable<any> {
        const url = `${this.urlEndpoint}/create`;
        const createPaymentRequestBody = {username, amount, days}
        return this.http.post(url, createPaymentRequestBody, { headers: this.header }).pipe(
            catchError((error) => {
              console.error('Error creating payment:', error);
              throw error; // Puedes personalizar cómo manejas el error aquí
            })
          );
        }

}