import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const user = localStorage.getItem('username');
    if (user) {
      // Si el usuario está presente, permite el acceso a la ruta
      return true;
    } else {
      // Si no hay un usuario, redirige al login
      this.router.navigate(['/login']);
      return false;
    }
  }
}
