import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-instructions-modal',
  templateUrl: './instructions-modal.component.html',
  styleUrls: ['./instructions-modal.component.css']
})
export class InstructionsModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InstructionsModalComponent>, private router: Router) { }

  ngOnInit() {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  navigateToTest(tipoAtencion: string) {
    this.router.navigate(['/test', { tipoAtencion }]);
    this.closeDialog();
  }
}
