import { routing } from "./app.routing";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { RegisterComponent } from "./register/register.component";
import { FormsModule } from "@angular/forms";
import { LoginComponent } from './login/login.component';
import { TestComponent } from './test/test.component';
import { MatSliderModule } from "@angular/material/slider"; // Importa MatSliderModule en lugar de MatSlider
import { HttpClientModule } from '@angular/common/http';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MatButtonModule, MatIconModule, MatListModule, MatSidenavModule} from "@angular/material";
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import { ProfileComponent } from './profile/profile.component';
import { HistoryComponent } from './history/history.component';
import { DoubtsComponent } from './doubts/doubts.component';
import { SuscriptionComponent } from './suscription/suscription.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { TestDialogComponent } from './test/test-dialog/test-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ExpirationModalComponent } from './main-menu/expiration-modal/expiration-modal.component';
import { InstructionsModalComponent } from './main-menu/instructions-modal/instructions-modal.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { ChangePasswordModalComponent } from './password-forgot/change-password-modal/change-password-modal.component';

@NgModule({
  declarations: [AppComponent, RegisterComponent, LoginComponent, TestComponent, MainMenuComponent, ProfileComponent, HistoryComponent, DoubtsComponent, SuscriptionComponent, CalculatorComponent, TestDialogComponent, ExpirationModalComponent, InstructionsModalComponent, LegalNoticeComponent, PasswordForgotComponent, ChangePasswordModalComponent],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    HttpClientModule,
    MatSliderModule,
    MatSidenavModule,
    MatListModule,
    NoopAnimationsModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [],
  entryComponents: [
    TestDialogComponent,
    ExpirationModalComponent,
    InstructionsModalComponent,
    ChangePasswordModalComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
