import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from '../model/user';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { routing } from '../app.routing';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  resultUser: string
  errorMessage: string;
  waitMessage: string;
  user: User;

  name: string;
  surnames: string;
  dni: string;
  email: string;
  tlf: string;
  startDate: Date;
  endDate: Date;
  subscription: string;

  attempts: number = 0;
  isButtonDisabled: boolean = false;
  timeoutDuration: number = 0;

  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    if (window.localStorage.getItem("username") != null) {
      this.router.navigate(['mainMenu']);
    }
  }

  login() {
    if (this.isButtonDisabled) return;

    if (!this.username || !this.password) {
      this.errorMessage = 'Por favor, ingresa tanto el nombre de usuario como la contraseña.';
      return; // Detener la ejecución si los campos están vacíos
    }

    this.isButtonDisabled = true;
    console.log(this.attempts);
    this.userService.login(this.username, this.password).subscribe(
      response => {
        this.attempts = 0;
        this.isButtonDisabled = false;
        this.waitMessage = ''; // Limpiar el mensaje de espera una vez que el login sea exitoso
        // Muestra el texto de la respuesta en la consola
        window.localStorage.setItem("username", this.username);
        this.router.navigate(['mainMenu']);
      },
      error => {
        this.attempts++;
        if (this.attempts > 3) {
          this.timeoutDuration = Math.min(3000 * this.attempts, 15000); // Máximo 15 segundos
          this.waitMessage = `Espere ${this.timeoutDuration / 1000} segundos antes de intentar nuevamente.`;
        }
        // Manejar errores aquí
        this.errorMessage = 'Error durante el inicio de sesión. Por favor, verifica tus credenciales.';
        setTimeout(() => {
          this.isButtonDisabled = false;
          this.waitMessage = ''; // Limpiar el mensaje después de que se habilite el botón
        }, this.timeoutDuration);
      }
    );
  }
}
