import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent implements OnInit {

  codeEmail: string; // Para almacenar el código ingresado
  password: string;
  passwordConfirm: string;
  errorMessage: string;
  email: string;
  username: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePasswordModalComponent>, private router: Router, private userService: UserService) { }

  ngOnInit() {

  }

  closeDialog(): void {
    this.router.navigate(['/login']); // Redirigir a la página de inicio de sesión
    this.dialogRef.close();
  }

  changePassword() {
    // Comparar el código ingresado con el código enviado
    if (this.codeEmail !== this.data.code.toString()) {
      this.errorMessage = "El código ingresado no es correcto.";
      return;
    }

    // Verificar si las contraseñas coinciden
    if (this.password !== this.passwordConfirm) {
      this.errorMessage = "Las contraseñas no coinciden.";
      return;
    }

    this.changePasswordAPI();
    this.closeDialog();
  }


  //CAMBIAR LAS CONTRASEÑAS CON BLOQUEO (BACK)
  changePasswordAPI() {
    this.userService.changeUserPassword(this.data.username, this.password)
      .subscribe(
        (response: any) => {
          this.errorMessage = "Contraseña cambiada con éxito"
        },
        error => {
          this.errorMessage = "Error al cambiar la contraseña"
        }
      );
  }
}
