import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { Observable, throwError } from 'rxjs';
import { map, tap } from "rxjs/operators"
import { catchError } from "rxjs/operators"

@Injectable({ providedIn: 'root' })

export class QuestionService {
    private urlEndpoint: string = 'https://public-area-7744833acd72.herokuapp.com/v1/test';
    private header = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient, private router: Router) {

    }

    getQuestions(): Observable<any[]> {
        return this.http.get<any[]>(this.urlEndpoint.concat('/create'), { headers: this.header })
            .pipe(
                catchError((error) => {
                    // Manejar errores aquí si es necesario
                    console.error('Error fetching questions:', error);
                    return [];
                })
            );
    }
    getQuestionsByAptitude(aptitudePathParam: string): Observable<any[]> {
        const url = `${this.urlEndpoint}/create/${aptitudePathParam}`;

        return this.http.get<any[]>(url, { headers: this.header }).pipe(
            catchError((error) => {
                console.error('Error fetching questions:', error);
                return [];
            })
        );
    }
    saveExam(username: string, aptitude: string, correct: number, failed: number, total: number, noResponded: number): Observable<any> {
        const url = `${this.urlEndpoint}/save`;
        const questionResults = { username, aptitude, correct, failed, total, noResponded};
        return this.http.post(url, questionResults, { headers: this.header }).pipe(
          catchError((error) => {
            console.error('Error saving exam:', error);
            throw error; // Puedes personalizar cómo manejas el error aquí
          })
        );
      }
}