import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expiration-modal',
  templateUrl: './expiration-modal.component.html',
  styleUrls: ['./expiration-modal.component.css']
})
export class ExpirationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExpirationModalComponent>, private router: Router) { }

  ngOnInit() {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  goSuscription(): void {
    this.router.navigate(['/suscription']);
    this.closeDialog();
  }
}
