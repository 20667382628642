import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { TestService } from '../services/test.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  isSidenavOpen: boolean = true;

  onSidenavOpen() {
    this.isSidenavOpen = true;
  }

  onSidenavClose() {
    this.isSidenavOpen = false;
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }

  examRecord: any[] = [];
  username: string;

  correctAnswersCount: number = 0;
  incorrectAnswersCount: number = 0;
  aptitude: string;

  constructor(private examService: TestService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.username = window.localStorage.getItem("username");
    this.getExamRecord(this.username);
  }

  getExamRecord(username: string): void {
    this.activatedRoute.params.subscribe(params => {
      this.examService.getExamRecord(username).subscribe(
        (examRecord) => {
          // Manejar las preguntas aquí
          this.examRecord = examRecord;
        },
        (error) => {
          // Manejar errores aquí si es necesario
        }
      );
    });

  }
}
