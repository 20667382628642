import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { User } from '../model/user';
import { MatDialog } from '@angular/material';
import { ExpirationModalComponent } from './expiration-modal/expiration-modal.component';
import { InstructionsModalComponent } from './instructions-modal/instructions-modal.component';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent {
  isSidenavOpen: boolean = true;

  onSidenavOpen() {
    this.isSidenavOpen = true;
  }

  onSidenavClose() {
    this.isSidenavOpen = false;
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }

  user: User;

  primeraConexion: string = "";
  currentDate = new Date();
  twoWeeksFromNow = new Date();

  username: string;
  name: string;
  surnames: string;
  dni: string;
  email: string;
  tlf: string;
  startDate: Date;
  endDate: Date;
  subscription: string;
  password: string;
  newPassword: string;
  newPasswordConfirmed: string;
  role: string;

  constructor(private userService: UserService, private router: Router, public expirationModal: MatDialog, public instructionsModal: MatDialog) { }


  navigateToTest(tipoAtencion: string) {
    this.instructionsModal.open(InstructionsModalComponent, {
      data: {
        tipoAtencion: tipoAtencion,
      }
    });
  }

  navigateToTestMemory(tipoAtencion: string) {
    this.router.navigate(['/test', { tipoAtencion }]);
  }

  ngOnInit() {
    if (window.localStorage.getItem("username") != null) {
      this.username = window.localStorage.getItem("username");
      this.userService.getUserInfo(this.username)
        .subscribe(
          (userData: User) => {
            this.user = userData;
            this.endDate = this.user.endDate;
            this.startDate = this.user.startDate;
            this.subscription = this.user.subscription;
            this.username = this.user.username;
            this.role = this.user.role;

            if (this.noActiveSuscription(this.user, this.endDate) && this.role == 'user') {
              this.router.navigate(['/suscription']);
            } else {
              this.primeraConexion = sessionStorage.getItem('primeraConexion')
              //Tiempo hasta caducidad
              this.endDate = new Date(this.user.endDate);
              this.twoWeeksFromNow.setDate(this.currentDate.getDate() + 14);
              if (this.primeraConexion != "si" && this.endDate.getTime() < this.twoWeeksFromNow.getTime()) {
                sessionStorage.setItem('primeraConexion', "si");
                var formattedDate = this.endDate.toLocaleDateString()
                this.expirationModal.open(ExpirationModalComponent, {
                  data: {
                    formattedDate: formattedDate,
                  }
                });
              }
            }
          },
          error => {
            // Manejar errores según tus necesidades.
          }
        );
    }
  }

  noActiveSuscription(user: User, endDate: Date) {
    var currentDate = new Date();
    var endDateDate = new Date(endDate);
    if (currentDate > endDateDate) {
      return true;
    } else {
      return false;
    }
  }

  openNotes() {
    window.open('https://academialepanto.com/apuntes-y-ejercicios', '_blank');
  }
}