import { Component, OnInit } from '@angular/core';
import { SuscriptionService } from '../services/suscription.service';
import { UserService } from '../services/user.service';
import { User } from '../model/user';

@Component({
  selector: 'app-suscription',
  templateUrl: './suscription.component.html',
  styleUrls: ['./suscription.component.css']
})
export class SuscriptionComponent implements OnInit {
  isSidenavOpen: boolean = true;

  onSidenavOpen() {
    this.isSidenavOpen = true;
  }

  onSidenavClose() {
    this.isSidenavOpen = false;
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }

  message: string;
  subject: string;
  username: string;
  email: string;
  endDate: Date;
  formattedDate: string = '';
  showFiller = false;

  user: User;

  amount : number;
  currency = "EUR"
  days: number;

  constructor(private suscriptionService: SuscriptionService, private userService: UserService) { }

  ngOnInit() {
    this.username = window.localStorage.getItem("username");
    this.userService.getUserInfo(this.username)
      .subscribe(
        (userData: User) => {
          this.user = userData;
          this.email = this.user.email;
          this.endDate = this.user.endDate;
          this.endDate = new Date(this.user.endDate);
          const today = new Date();
          if (this.endDate < today) {
            this.formattedDate = "Su suscripción no está activa.";
          } else {
            this.formattedDate = "Suscripcion activa hasta: " + this.endDate.toLocaleDateString();
          }
          // Hacer algo con la información del usuario, por ejemplo, mostrarla en la interfaz de usuario.
        },
        error => {
          // Manejar errores según tus necesidades.
        }
      );
  }

  createPayment(days, amount) {
    this.suscriptionService.createPayment(amount, days, this.username).subscribe(
      (payment) => {
        window.open(payment.redirectUrl, 'PaymentWindow', 'width=600,height=1000');
        // Realizar acciones adicionales si es necesario
      },
      (error) => {
        // Puedes manejar el error aquí si es necesario
      }
    );
  }


  /*   redirectToPayment() {
  
      const paymentData = {
        amount: 100,
        currency: "EUR",
        username: "acarrfern"
      };
  
      fetch('https://public-area-7744833acd72.herokuapp.com/v1/payment/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(paymentData)
      })
      .then(response => {
        // Manejar la respuesta si es necesario
        console.log('Respuesta recibida:', response);
        // Redirigir al usuario a la página de pago
        window.location.href = 'https://public-area-7744833acd72.herokuapp.com/v1/payment/create';
      })
      .catch(error => {
        // Manejar errores si ocurren
        console.error('Se produjo un error al realizar la solicitud:', error);
      });
    } */

}
