import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../model/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  isSidenavOpen: boolean = true;

  onSidenavOpen() {
    this.isSidenavOpen = true;
  }

  onSidenavClose() {
    this.isSidenavOpen = false;
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }

  user: User;

  username: string;
  name: string;
  surnames: string;
  dni: string;
  email: string;
  tlf: string;
  startDate: Date;
  endDate: Date;
  subscription: string;
  password: string;
  newPassword: string;
  newPasswordConfirmed: string;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    const username = window.localStorage.getItem("username"); // Puedes obtener esto dinámicamente según tus necesidades
    this.userService.getUserInfo(username)
      .subscribe(
        (userData: User) => {
          this.user = userData;

          this.dni = this.user.dni;
          this.email = this.user.email;
          this.endDate = this.user.endDate;
          this.name = this.user.name;
          this.startDate = this.user.startDate;
          this.subscription = this.user.subscription;
          this.surnames = this.user.surnames;
          this.tlf = this.user.tlf;
          this.username = this.user.username;
          this.password = this.user.password;
          // Hacer algo con la información del usuario, por ejemplo, mostrarla en la interfaz de usuario.
        },
        error => {
          // Manejar errores según tus necesidades.
        }
      );
  }

  closeSession() {
    window.localStorage.removeItem("username");
    this.router.navigate(['login']);
  }

}


