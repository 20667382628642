import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { Observable, throwError } from 'rxjs';
import { map, tap } from "rxjs/operators"
import { catchError } from "rxjs/operators"

@Injectable({ providedIn: 'root' })

export class UserService {
  private urlEndpoint: string = 'https://public-area-7744833acd72.herokuapp.com/v1/user';
  //private urlEndpoint: string = 'http://localhost:8080/v1/user';


  private header = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private router: Router) {

  }

  login(username: string, password: string): Observable<string> {
    const user = { username, password };

    return this.http.post(this.urlEndpoint.concat('/login'), user, { headers: this.header, responseType: 'text' });
  }

  register(username: string, password: string, name: string, surnames: string, dni: string, tlf: string, email: string, startDate: Date, role: string, subscription: string): Observable<User> {
    const user = { username, password, name, surnames, dni, tlf, email, startDate, role, subscription };
    return this.http.post<User>(this.urlEndpoint.concat('/create'), user, { headers: this.header });
  }

  getUserInfo(username: string): Observable<User> {
    const url = `${this.urlEndpoint}/profile/${username}`;
    return this.http.get<User>(url, { headers: this.header });
  }

  changeUserPassword(username: string, newPassword: string): Observable<string> {
    const newPasswordUser = { username, newPassword };
    return this.http.post(this.urlEndpoint.concat('/profile/change-password'), newPasswordUser, { headers: this.header, responseType: 'text' });
  }

  sendEmailPassword(email: string): Observable<string> {
    const user = { email };
    return this.http.post(this.urlEndpoint.concat('/sendEmailPassword'), user, { headers: this.header, responseType: 'text' });
  }
}