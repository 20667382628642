import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QuestionService } from '../services/question.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { TestDialogComponent } from './test-dialog/test-dialog.component';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  @ViewChild('btnA', { static: false }) btnA: ElementRef;
  @ViewChild('btnB', { static: false }) btnB: ElementRef;
  @ViewChild('btnC', { static: false }) btnC: ElementRef;
  @ViewChild('btnD', { static: false }) btnD: ElementRef;

  questions: any[] = [];
  userAnswer: string = '';
  currentResponse: string;
  currentIndex: number = 0;

  formattedTime: string = '';
  time: number = 1000;
  testFinished: boolean = false;

  selectedOption: string;
  finishButtonEnabled: boolean = true;

  numTotalAnswers: number = 0;
  numRightAnswers: number = 0;
  numFailedAnswers: number = 0;
  numQuestions: number = 0;
  numNoResponded: number = 0;

  currentQuestion: string = '';
  currentQuestionCode: number;
  currentQuestionAptitude: string = '';
  currentQuestionCategory: string = '';
  currentQuestionAnswer1: string = '';
  currentQuestionAnswer2: string = '';
  currentQuestionAnswer3: string = '';
  currentQuestionAnswer4: string = '';
  currentQuestionExplication: string = '';
  currentQuestionImage: string = '';
  currentQuestionImageA1: string = '';
  currentQuestionImageA2: string = '';
  currentQuestionImageA3: string = '';
  currentQuestionImageA4: string = '';

  currentQuestionAnswer1Correct: boolean;
  currentQuestionAnswer2Correct: boolean;
  currentQuestionAnswer3Correct: boolean;
  currentQuestionAnswer4Correct: boolean;


  username: string;
  password: string;
  resultUser: string
  errorMessage: string;
  aptitudePathParam: string;

  showExplicationH2 = false;

  tipoAtencion: string;


  constructor(private questionService: QuestionService, private router: Router, private activatedRoute: ActivatedRoute, public testDialog: MatDialog) {
  }

  ngOnInit() {
    setInterval(() => {
      if (!this.testFinished) {
        this.updateCont();
      }
    }, 1000); // Ejecutar cada segundo

    this.username = window.localStorage.getItem("username");
    this.activatedRoute.params.subscribe(params => {
      this.tipoAtencion = params['tipoAtencion'];
      switch (this.tipoAtencion) {
        case 'VERBAL':
          this.time = 420;
          break;
        case 'ESPACIAL':
          this.time = 450;
          break;
        case 'NUMÉRICO':
          this.time = 540;
          break;
        case 'MECÁNICA':
          this.time = 450;
          break;
        case 'RA':
          this.time = 450;
          break;
        case 'ATENCIÓN':
          this.time = 450;
          break;
        case 'undefined':
          this.time = 2760;
          break;
        case 'MEMORIA':
          this.time = 1000000;
      }
      if (this.tipoAtencion != "undefined") {
        this.aptitudePathParam = this.tipoAtencion;
        this.questionService.getQuestionsByAptitude(this.aptitudePathParam).subscribe(
          (questions) => {
            // Manejar las preguntas aquí
            this.questions = questions;
            this.numQuestions = this.questions.length;
            this.displayCurrentQuestion();
          }
          ,
          (error) => {
            // Manejar errores aquí si es necesario
          }
        );
      } else {
        this.questionService.getQuestions().subscribe(
          (questions) => {
            // Manejar las preguntas aquí
            this.questions = questions;
            this.numQuestions = this.questions.length;
            this.displayCurrentQuestion();
          },

          (error) => {
            // Manejar errores aquí si es necesario
          }
        );

      }
    });
  }

  skipToNextAptitude() {
    const currentAptitude = this.currentQuestionAptitude;

    // Encuentra el índice de la siguiente pregunta con una aptitud distinta
    for (let i = this.currentIndex + 1; i < this.questions.length; i++) {
      if (this.questions[i].aptitude !== currentAptitude) {
        this.currentIndex = i; // Salta a la siguiente aptitud
        this.displayCurrentQuestion(); // Actualiza la visualización de la pregunta
        return;
      }
    }
  }

  displayCurrentQuestion() {
    // Lógica para mostrar la pregunta actual en tu interfaz
    this.currentQuestion = this.questions[this.currentIndex].question;
    this.currentQuestionCode = this.questions[this.currentIndex].questionCode;
    this.currentQuestionAptitude = this.questions[this.currentIndex].aptitude;
    this.currentQuestionCategory = this.questions[this.currentIndex].category;

    this.currentQuestionAnswer1 = this.questions[this.currentIndex].answer1;
    this.currentQuestionAnswer2 = this.questions[this.currentIndex].answer2;
    this.currentQuestionAnswer3 = this.questions[this.currentIndex].answer3;
    this.currentQuestionAnswer4 = this.questions[this.currentIndex].answer4;
    this.currentQuestionExplication = this.questions[this.currentIndex].explication;
    this.userAnswer = this.questions[this.currentIndex].userAnswer;
    const base64Image = this.questions[this.currentIndex].images;
    this.currentQuestionImage = base64Image !== null ? "data:image/png;base64," + base64Image : null;

    const base64ImageA1 = this.questions[this.currentIndex].imageAnswer1;
    this.currentQuestionImageA1 = base64ImageA1 !== null ? "data:image/png;base64," + base64ImageA1 : null;

    const base64ImageA2 = this.questions[this.currentIndex].imageAnswer2;
    this.currentQuestionImageA2 = base64ImageA2 !== null ? "data:image/png;base64," + base64ImageA2 : null;

    const base64ImageA3 = this.questions[this.currentIndex].imageAnswer3;
    this.currentQuestionImageA3 = base64ImageA3 !== null ? "data:image/png;base64," + base64ImageA3 : null;

    const base64ImageA4 = this.questions[this.currentIndex].imageAnswer4;
    this.currentQuestionImageA4 = base64ImageA4 !== null ? "data:image/png;base64," + base64ImageA4 : null;

    if (this.currentQuestionAptitude === 'MEMORIA') {
      // Si la aptitud es "memoria", ocultar la pregunta y los botones
      this.hideQuestionAndButtons();
      setTimeout(() => {
        // Después de 12 segundos, mostrar la pregunta y los botones
        this.showQuestionAndButtons();
        this.enableAnswerButtons();
      }, 12000); // 12 segundos en milisegundos
    } else {
      if (this.questions[this.currentIndex].responded) {
        // Si ya ha sido respondida, deshabilita los botones de respuesta
        this.disableAnswerButtons();
      } else {
        // Si no ha sido respondida, habilita los botones de respuesta
        this.enableAnswerButtons();
      }
    }
  }

  hideQuestionAndButtons() {
    // Ocultar la pregunta y los botones
    this.disableAnswerButtons();
    this.currentQuestion = '';
    this.currentQuestionAnswer1 = '';
    this.currentQuestionImageA1 = '';
    this.currentQuestionAnswer2 = '';
    this.currentQuestionImageA2 = '';
    this.currentQuestionAnswer3 = '';
    this.currentQuestionImageA3 = '';
    this.currentQuestionAnswer4 = '';
    this.currentQuestionImageA4 = '';
  }


  showQuestionAndButtons() {
    // Mostrar la pregunta y los botones
    this.enableAnswerButtons();
    this.currentQuestion = this.questions[this.currentIndex].question;
    this.currentQuestionAnswer1 = this.questions[this.currentIndex].answer1;
    this.currentQuestionAnswer2 = this.questions[this.currentIndex].answer2;
    this.currentQuestionAnswer3 = this.questions[this.currentIndex].answer3;
    this.currentQuestionAnswer4 = this.questions[this.currentIndex].answer4;
    this.currentQuestionImage = '';

    const base64ImageA1 = this.questions[this.currentIndex].imageAnswer1;
    this.currentQuestionImageA1 = base64ImageA1 !== null ? "data:image/png;base64," + base64ImageA1 : null;

    const base64ImageA2 = this.questions[this.currentIndex].imageAnswer2;
    this.currentQuestionImageA2 = base64ImageA2 !== null ? "data:image/png;base64," + base64ImageA2 : null;

    const base64ImageA3 = this.questions[this.currentIndex].imageAnswer3;
    this.currentQuestionImageA3 = base64ImageA3 !== null ? "data:image/png;base64," + base64ImageA3 : null;

    const base64ImageA4 = this.questions[this.currentIndex].imageAnswer4;
    this.currentQuestionImageA4 = base64ImageA4 !== null ? "data:image/png;base64," + base64ImageA4 : null;
  }

  nextQuestion() {
    // Avanzar al siguiente índice
    this.currentIndex = (this.currentIndex + 1) % this.questions.length;
    this.displayCurrentQuestion();
  }

  prevQuestion() {
    // Retroceder al índice anterior
    this.currentIndex = (this.currentIndex - 1 + this.questions.length) % this.questions.length;
    this.displayCurrentQuestion();
  }

  exitTest() {
    this.router.navigate(['/mainMenu']);
  }

  showExplicationA() {
    this.selectedOption = 'A'
    this.questions[this.currentIndex].userAnswer = 'A';
    this.currentQuestionAnswer1Correct = this.questions[this.currentIndex].answer1Correct;
    if (this.currentQuestionAnswer1Correct == true) {
      this.currentQuestionExplication = "CORRECTO! " + this.currentQuestionExplication;
      this.numRightAnswers = (this.numRightAnswers + 1);
    } else {
      this.currentQuestionExplication = "ERROR! " + this.currentQuestionExplication;
      this.numFailedAnswers = (this.numFailedAnswers + 1);
    }
    this.numTotalAnswers = (this.numTotalAnswers + 1);
    this.disableAnswerButtons();
    this.questions[this.currentIndex].responded = true;
    this.nextQuestion();

    if (this.tipoAtencion != "undefined" && this.numTotalAnswers == 15) {
      this.endTest();
    }
    if (this.tipoAtencion == "undefined" && this.numTotalAnswers == 105) {
      this.endTest();
    }
  }

  showExplicationB() {
    this.selectedOption = 'B'
    this.questions[this.currentIndex].userAnswer = 'B';
    this.currentQuestionAnswer2Correct = this.questions[this.currentIndex].answer2Correct;
    if (this.currentQuestionAnswer2Correct == true) {
      this.currentQuestionExplication = "CORRECTO! " + this.currentQuestionExplication;
      this.numRightAnswers = (this.numRightAnswers + 1);
    } else {
      this.numFailedAnswers = (this.numFailedAnswers + 1);
      this.currentQuestionExplication = "ERROR! " + this.currentQuestionExplication;
    }
    this.numTotalAnswers = (this.numTotalAnswers + 1);
    this.disableAnswerButtons();
    this.questions[this.currentIndex].responded = true;
    this.nextQuestion();

    if (this.tipoAtencion != "undefined" && this.numTotalAnswers == 15) {
      this.endTest();
    }
    if (this.tipoAtencion == "undefined" && this.numTotalAnswers == 105) {
      this.endTest();
    }
  }

  showExplicationC() {
    this.selectedOption = 'C'
    this.questions[this.currentIndex].userAnswer = 'C';
    this.currentQuestionAnswer3Correct = this.questions[this.currentIndex].answer3Correct;
    if (this.currentQuestionAnswer3Correct == true) {
      this.currentQuestionExplication = "CORRECTO! " + this.currentQuestionExplication;
      this.numRightAnswers = (this.numRightAnswers + 1);
    } else {
      this.numFailedAnswers = (this.numFailedAnswers + 1);
      this.currentQuestionExplication = "ERROR! " + this.currentQuestionExplication;
    }
    this.numTotalAnswers = (this.numTotalAnswers + 1);
    this.disableAnswerButtons();
    this.questions[this.currentIndex].responded = true;
    this.nextQuestion();

    if (this.tipoAtencion != "undefined" && this.numTotalAnswers == 15) {
      this.endTest();
    }
    if (this.tipoAtencion == "undefined" && this.numTotalAnswers == 105) {
      this.endTest();
    }
  }


  showExplicationD() {
    this.selectedOption = 'D'
    this.questions[this.currentIndex].userAnswer = 'D';
    this.currentQuestionAnswer4Correct = this.questions[this.currentIndex].answer4Correct;
    if (this.currentQuestionAnswer4Correct == true) {
      this.currentQuestionExplication = "CORRECTO! " + this.currentQuestionExplication;
      this.numRightAnswers = (this.numRightAnswers + 1);
    } else {
      this.numFailedAnswers = (this.numFailedAnswers + 1);
      this.currentQuestionExplication = "ERROR! " + this.currentQuestionExplication;
    }
    this.numTotalAnswers = (this.numTotalAnswers + 1);
    this.disableAnswerButtons();
    this.questions[this.currentIndex].responded = true;
    this.nextQuestion();

    if (this.tipoAtencion != "undefined" && this.numTotalAnswers == 15) {
      this.endTest();
    }
    if (this.tipoAtencion == "undefined" && this.numTotalAnswers == 105) {
      this.endTest();
    }
  }

  disableAnswerButtons() {
    this.btnA.nativeElement.disabled = true;
    this.btnB.nativeElement.disabled = true;
    this.btnC.nativeElement.disabled = true;
    this.btnD.nativeElement.disabled = true;
  }
  enableAnswerButtons() {
    this.btnA.nativeElement.disabled = false;
    this.btnB.nativeElement.disabled = false;
    this.btnC.nativeElement.disabled = false;
    this.btnD.nativeElement.disabled = false;
  }

  saveExam() {
    const numFailedAnswers = this.numTotalAnswers - this.numRightAnswers;
    this.questionService.saveExam(this.username, this.aptitudePathParam, this.numRightAnswers, numFailedAnswers, this.numTotalAnswers, this.numNoResponded).subscribe(
      (response) => {
        // Realizar acciones adicionales si es necesario
      },
      (error) => {
        // Puedes manejar el error aquí si es necesario
      }
    );
  }

  private updateCont() {
    if (!this.testFinished && this.time > 0) { // Agrega la condición para verificar si el test está finalizado
      this.time--;
      this.actualizarTiempoEnPantalla();
    } else {
      this.endTest();
      // Aquí puedes manejar lo que sucede cuando el tiempo llega a 0 o el test está finalizado
    }
  }

  private actualizarTiempoEnPantalla() {
    if (this.tipoAtencion == 'MEMORIA') {
      this.time += 1000;
    }

    const minutos = Math.floor(this.time / 60);
    const segundos = this.time % 60;
    this.formattedTime = `${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
  }

  isFinished() {
    if (this.time <= 0) {
      this.testFinished = true;
    }
    if (this.numTotalAnswers === this.numQuestions) {
      this.testFinished = true;
    }
  }


  endTest() {
    this.questions.forEach(question => {
      question.responded = true;
    });
    this.testFinished = true;
    this.showExplicationH2 = true;
    this.disableAnswerButtons();
    this.numNoResponded = this.numQuestions - (this.numRightAnswers + this.numFailedAnswers);
    this.saveExam();
    this.testDialog.open(TestDialogComponent, {
      data: {
        numFailedAnswers: this.numFailedAnswers,
        numRightAnswers: this.numRightAnswers,
        numNoResponded: this.numNoResponded,
        numQuestions: this.numQuestions
      }
    });
  }

  finishTest() {
    this.finishButtonEnabled = false;
    this.questions.forEach(question => {
      question.responded = true;
    });
    this.endTest();
    this.showExplicationH2 = true;
    this.disableAnswerButtons();
  }
}
