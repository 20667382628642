import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { UserService } from '../services/user.service';
import { User } from '../model/user';

@Component({
  selector: 'app-doubts',
  templateUrl: './doubts.component.html',
  styleUrls: ['./doubts.component.css']
})
export class DoubtsComponent implements OnInit {

  isSidenavOpen: boolean = true;
  onSidenavOpen() {
    this.isSidenavOpen = true;
  }

  onSidenavClose() {
    this.isSidenavOpen = false;
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }


  message: string;
  subject: string;
  username: string;
  user: User;
  email: string;
  errorMessage: string;

  isButtonDisabled = false;

  constructor(private adminService: AdminService, private userService: UserService) { }

  ngOnInit() {
    if (window.localStorage.getItem("username") != null) {
      this.username = window.localStorage.getItem("username");
      this.userService.getUserInfo(this.username)
        .subscribe(
          (userData: User) => {
            this.user = userData;
            this.email = this.user.email;
            // Hacer algo con la información del usuario, por ejemplo, mostrarla en la interfaz de usuario.
          },
          error => {          // Manejar errores según tus necesidades.
          }
        );
    }
  }

  sendEmail() {
    if (!this.isButtonDisabled && this.message && this.message.trim() !== "") {
      this.isButtonDisabled = true;
      this.adminService.sendEmail(
        "Usuario: " + this.username + "\nCorreo: " + this.email + "\nMensaje: " + this.message,
        "Subject",
        this.email,
        "academialepantoheroku@gmail.com"
      ).subscribe(
        () => {
          this.errorMessage = "Correo enviado. Pronto será atendido.";
          this.message = '';
          this.isButtonDisabled = false;
        },
        (error) => {
          this.errorMessage = "Error al enviar el correo";
          this.isButtonDisabled = false;
        }
      );
    } else {
      this.errorMessage = "Por favor, escribe un mensaje antes de enviar.";
    }
  }
}  
