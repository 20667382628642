import { RouterModule } from "@angular/router";
import { RegisterComponent } from "./register/register.component";
import { LoginComponent } from "./login/login.component";
import { TestComponent } from "./test/test.component";
import { MainMenuComponent } from "./main-menu/main-menu.component";
import { ProfileComponent } from "./profile/profile.component";
import { HistoryComponent } from "./history/history.component";
import { DoubtsComponent } from "./doubts/doubts.component";
import { SuscriptionComponent } from "./suscription/suscription.component";
import { LegalNoticeComponent } from "./legal-notice/legal-notice.component";
import { AuthGuard } from "src/ath.guard";
import { PasswordForgotComponent } from "./password-forgot/password-forgot.component";

const appRoutes = [
    { path: "", component: RegisterComponent, pathMatch: "full" },
    { path: "login", component: LoginComponent, pathMatch: "full" },
    { path: "test", component: TestComponent, pathMatch: "full", canActivate: [AuthGuard]  },
    { path: "mainMenu", component: MainMenuComponent, pathMatch: "full", canActivate: [AuthGuard] },
    { path: "profile", component: ProfileComponent, pathMatch: "full", canActivate: [AuthGuard]  },
    { path: "progress", component: HistoryComponent, pathMatch: "full", canActivate: [AuthGuard]  },
    { path: "doubts", component: DoubtsComponent, pathMatch: "full", canActivate: [AuthGuard]  },
    { path: "suscription", component: SuscriptionComponent, pathMatch: "full", canActivate: [AuthGuard]  },
    { path: "legalNotice", component: LegalNoticeComponent, pathMatch: "full"},
    { path: "passwordForgot", component: PasswordForgotComponent, pathMatch: "full"},
];
export const routing = RouterModule.forRoot(appRoutes);