import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { AdminService } from '../services/admin.service';
import { UserService } from '../services/user.service';
import { User } from '../model/user';



@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.css']
})
export class PasswordForgotComponent implements OnInit {

  code: number;
  subject: string;
  username: string;
  email: string;
  user: User;
  errorMessage: string;
  isGeneratingCode: boolean = false; // Variable de bloqueo


  constructor(public changePasswordModal: MatDialog, private adminService: AdminService, private userService: UserService) { }

  generateCode() {
    if (this.isGeneratingCode) return; // Si ya está en proceso, salir de la función
    this.isGeneratingCode = true; // Activar el bloqueo
    // Hacer la comprobación de la información del usuario
    this.userService.getUserInfo(this.username).subscribe(
      (userData: User) => {
        // Verificar si el correo coincide con el registrado para ese usuario
        if (userData && userData.email === this.email) {
          this.user = userData;
          this.username = userData.username;
          this.email = userData.email;
          // Generar un código de 6 dígitos
          this.code = Math.floor(100000 + Math.random() * 900000);

          // Enviar el correo con el código generado
          this.sendEmail();

          // Abrir el modal pasando los datos
          this.changePasswordModal.open(ChangePasswordModalComponent, {
            data: {
              username: this.username,
              email: this.email,
              code: this.code
            },
            disableClose: true // Deshabilita el cierre del diálogo
          });
        } else {
          this.errorMessage = "No coinciden los datos";
          // Aquí podrías manejar el error, por ejemplo, mostrar un mensaje al usuario.
        }
        this.isGeneratingCode = false; // Desactivar el bloqueo al finalizar

      },
      error => {
        this.errorMessage = "Error obteniendo información del usuario";
        // Manejar el error, por ejemplo, mostrar un mensaje de error en la UI
      }
    );
  }



  sendEmail() {
    this.adminService.sendEmail("ESTE ES SU CÓDIGO PARA " + this.username + " :\n " + this.code, "Cambio de contraseña", "academialepantoheroku@gmail.com", this.email).subscribe(
      () => {
        this.errorMessage = "Email enviado";
      },
      (error) => {
        this.errorMessage = "Error al enviar el email";
      }
    );
  }


  ngOnInit() {

  }

}

