import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { Observable, throwError } from 'rxjs';
import { map, tap } from "rxjs/operators"
import { catchError } from "rxjs/operators"

@Injectable({ providedIn: 'root' })

export class TestService {
    private urlEndpoint: string = 'https://public-area-7744833acd72.herokuapp.com/v1/record';
    private header = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient, private router: Router) {

    }
    getExamRecord(username: string): Observable<any[]> {
        const url = `${this.urlEndpoint}/${username}`;

        return this.http.get<any[]>(url, { headers: this.header }).pipe(
            catchError((error) => {
                console.error('Error getting records:', error);
                return [];
            })
        );
    }
}