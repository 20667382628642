import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { Observable, throwError } from 'rxjs';
import { map, tap } from "rxjs/operators"
import { catchError } from "rxjs/operators"

@Injectable({ providedIn: 'root' })

export class AdminService {
  private urlEndpoint: string = 'https://public-area-7744833acd72.herokuapp.com/v1/support';
  private header = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private router: Router) {
  }

  sendEmail(message: string, subject: string, originEmail: string, destinationEmail: string): Observable<any> {
    const url = `${this.urlEndpoint}/send-email`;
    const sendEmailRequestBody = { message, subject, originEmail, destinationEmail };
    return this.http.post(url, sendEmailRequestBody, { headers: this.header }).pipe(
      catchError((error) => {
        console.error('Error sending email:', error);
        throw error; // Puedes personalizar cómo manejas el error aquí
      })
    );
  }
}
